import classnames from 'classnames';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { useBrowserDetails } from '../../common/hooks/useBrowserDetails';
import { imageInViewOptions, lazyLoadClassName } from '../../common/loading';
import { AspectRatioImageProps } from './AspectRatioImage.props';
import { StyledAspectContainer, StyledBackgroundImage, StyledForegroundImage } from './AspectRatioImage.styles';

const showBackground = (src: string) => !/\.svg$/.test(src);

export const AspectRatioImage = ({ image, lazyLoad, ratio }: AspectRatioImageProps) => {
  const [inViewRef, inView] = useInView(imageInViewOptions);
  const { browserDetails } = useBrowserDetails();

  const add3dTransform = browserDetails.name === 'safari'; // Needed to prevent lag during zoom due to blur filter
  const deferImageLoad = lazyLoad && !inView;

  return (
    <StyledAspectContainer ratio={ratio} ref={inViewRef}>
      {showBackground(image.src) && (
        <StyledBackgroundImage
          add3dTransform={add3dTransform}
          className={classnames({ [lazyLoadClassName]: deferImageLoad })}
          image={image}
        />
      )}
      <StyledForegroundImage
        aria-label={image.alt}
        className={classnames({ [lazyLoadClassName]: deferImageLoad })}
        imageSrc={image.src}
        role='img'
        title={image.alt}
      />
    </StyledAspectContainer>
  );
};
