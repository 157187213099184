import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth, widthBreakpoints } from '../../common/breakpoints';
import { black, white } from '../../common/colors';

export const StyledFeaturedBlogsSection = styled.section`
  display: block;
  color: ${white};
  height: 100%;
  position: relative;
`;

export const StyledBlogsContent = styled.div`
  background: ${black};

  // Default to flex layout for browsers that do not support grids (IE 11)
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  // IE 11 specific styles.
  .blog0,
  .blog1,
  .blog2,
  .blog3,
  .blog4 {
    @media (min-width: ${widthBreakpoints.tablet}px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 33%;
    }
  }

  @supports (display: grid) {
    display: grid;

    ${mediaQueryWidth({ min: 'tablet' })`
      grid-template-columns: repeat(4, 1fr);
    `}

    .blog0 {
      ${mediaQueryWidth({ min: 'tablet' })`
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      `}

      h3 {
        ${mediaQueryWidth({ min: 'tablet' })`
          font-size: ${rem(30)};
          line-height: ${rem(30)};
        `}
      }
    }

    .blog1 {
      ${mediaQueryWidth({ min: 'tablet' })`
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
      `}
    }

    .blog2 {
      ${mediaQueryWidth({ min: 'tablet' })`
        grid-column-start: 3;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 2;
      `}
    }

    .blog3 {
      ${mediaQueryWidth({ min: 'tablet' })`
        grid-column-start: 4;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
      `}
    }

    .blog4 {
      ${mediaQueryWidth({ min: 'tablet' })`
        grid-column-start: 4;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
      `}
    }
  }
`;

export const StyledBlogsButtonContainer = styled.div`
  padding: ${rem(16)} 0 ${rem(32)} 0;
  text-align: center;
`;
