import * as React from 'react';
import { black, white } from '../../common/colors';
import { Routes } from '../../common/constants';
import { getPrefixRedirectedUrl } from '../../common/helpers/prefix';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { useTranslations } from '../../common/translation';
import { Button } from '../button/Button';
import { RowHeader } from '../row-header/RowHeader';
import { FeaturedBlog } from './featured-blog/FeaturedBlog';
import { FeaturedBlogProps } from './featured-blog/FeaturedBlog.props';
import { FeaturedBlogsProps } from './FeaturedBlogs.props';
import { StyledBlogsButtonContainer, StyledBlogsContent, StyledFeaturedBlogsSection } from './FeaturedBlogs.styles';
import translations from './translations';

export const FeaturedBlogs = ({ blogs, headingTag, lazyLoad, showButton, showTitle, title }: FeaturedBlogsProps) => {
  const { t } = useTranslations(translations);
  const { isSmallScreen } = useWindowResize();

  if (!(blogs || []).length) {
    return null;
  }

  const articlesPath = getPrefixRedirectedUrl(Routes.articles);
  const blogCount = isSmallScreen ? 3 : 5;

  return (
    <StyledFeaturedBlogsSection>
      {title && showTitle && <RowHeader background={black} color={white} headingTag={headingTag} title={title} />}
      <StyledBlogsContent>
        {blogs.slice(0, blogCount).map((blog: FeaturedBlogProps, index: number) => (
          <div key={index} className={`blog${index}`}>
            <FeaturedBlog {...blog} lazyLoad={lazyLoad} />
          </div>
        ))}
      </StyledBlogsContent>
      {showButton && (
        <StyledBlogsButtonContainer>
          <Button href={articlesPath} invertColors={true}>
            {t('allArticlesButton', 'All Articles')}
          </Button>
        </StyledBlogsButtonContainer>
      )}
    </StyledFeaturedBlogsSection>
  );
};
