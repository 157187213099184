import styled, { css } from 'styled-components';
import { hoverAspectContainerStyles } from '../../aspect-ratio-image/AspectRatioImage.styles';

export const carouselItemGapPx = 20;

export const removeOutlineStyles = css`
  &:focus,
  &:active {
    outline: 0;
  }
`;

export const StyledCarouselItem = styled.div`
  ${hoverAspectContainerStyles}
  ${removeOutlineStyles}

  padding: 0 ${carouselItemGapPx}px 20px 0;
`;

export const StyledCarouselItemImage = styled.div`
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;

  & > a {
    display: block;
  }

  ${removeOutlineStyles}
`;
