import styled, { css } from 'styled-components';
import { grey10 } from '../../common/colors';
import { blurredBackgroundImageCss } from '../../common/helpers/image';
import { safeBGImageUrl } from '../../common/helpers/strings';
import { lazyLoadClassName } from '../../common/loading';

const SCALE_IMAGE_RATIO: number = 1.08;

export const StyledAspectContainer = styled.div<{ ratio: number }>`
  position: relative;
  width: auto;
  height: 0;
  padding-top: ${({ ratio }) => ratio * 100}%;
  background-color: ${grey10};
  overflow: hidden;
`;

export const StyledForegroundImage = styled.div<{ imageSrc: string }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: ${({ imageSrc }) => safeBGImageUrl(imageSrc)};
  background-position: center center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: 0.4s ease;

  &:hover {
    transform: scale(${SCALE_IMAGE_RATIO});
  }

  &.${lazyLoadClassName} {
    background-image: none;
  }
`;

export const StyledBackgroundImage = styled.div<{ add3dTransform?: boolean; image: Image }>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  ${({ add3dTransform, image }) => blurredBackgroundImageCss(image, add3dTransform)}

  &.${lazyLoadClassName} {
    background-image: none;
  }
`;

export const hoverAspectContainerStyles = css`
  &:hover {
    ${StyledAspectContainer} {
      ${StyledForegroundImage} {
        transform: scale(${SCALE_IMAGE_RATIO});
      }
    }
  }
`;
