import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { black, darkGrey2 } from '../../../common/colors';
import { removeOutlineStyles } from '../carousel-item/CarouselItem.styles';

export const StyledCarouselItemHeader = styled.header`
  margin-top: ${rem(15)};
  padding: 0 ${rem(5)};

  ${removeOutlineStyles}

  ${mediaQueryWidth({ min: 'tablet' })`
    padding: 0 ${rem(8)};
  `};

  span {
    padding: ${rem(2)} ${rem(4)};
    font-size: ${rem(10)};
    font-weight: 400;
    line-height: ${rem(13)};
    color: ${black};
    background-color: #eeeeee;
  }

  p {
    margin: 0;
    font-size: ${rem(13)};
    line-height: 1.3;
  }
`;

export const StyledCarouselItemTitle = styled.div`
  margin: ${rem(5)} 0;
  line-height: 0;
  text-transform: none;
  cursor: pointer;

  a {
    display: inline-block;
    max-width: 100%;
    font-size: ${rem(17)};
    line-height: 1.2;
    color: ${darkGrey2};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
