import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Slider, { ResponsiveObject } from 'react-slick';
import {
  adjustButtonPosition,
  adjustSlideAttributes,
  defaultSliderSettings,
  getSlidesToShow,
} from '../../common/helpers/carousel';
import { useDebug } from '../../common/hooks/useDebug';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { HeadingTag } from '../../common/interfaces';
import { imageInViewOptions } from '../../common/loading';
import { StyledPaddingContainer } from '../../common/padding.styles';
import { useTranslations } from '../../common/translation';
import { CarouselArrow } from '../carousel-arrow/CarouselArrow';
import { RowHeader } from '../row-header/RowHeader';
import { carouselItemTitleValue } from './carousel-item-title/CarouselItemTitle';
import { CarouselItem } from './carousel-item/CarouselItem';
import { carouselItemGapPx } from './carousel-item/CarouselItem.styles';
import { TitleCarouselNode, TitleCarouselProps } from './TitleCarousel.props';
import { StyledTitleCarousel, StyledTitleSliderContainer } from './TitleCarousel.styles';
import translations from './translations';

const maxSlidesToShow = 20;

const responsive: ResponsiveObject[] = [
  {
    breakpoint: 4013,
    settings: {
      slidesToShow: 19,
      slidesToScroll: 19,
    },
  },
  {
    breakpoint: 3802,
    settings: {
      slidesToShow: 18,
      slidesToScroll: 18,
    },
  },
  {
    breakpoint: 3591,
    settings: {
      slidesToShow: 17,
      slidesToScroll: 17,
    },
  },
  {
    breakpoint: 3380,
    settings: {
      slidesToShow: 16,
      slidesToScroll: 16,
    },
  },
  {
    breakpoint: 3169,
    settings: {
      slidesToShow: 15,
      slidesToScroll: 15,
    },
  },
  {
    breakpoint: 2958,
    settings: {
      slidesToShow: 14,
      slidesToScroll: 14,
    },
  },
  {
    breakpoint: 2747,
    settings: {
      slidesToShow: 13,
      slidesToScroll: 13,
    },
  },
  {
    breakpoint: 2536,
    settings: {
      slidesToShow: 12,
      slidesToScroll: 12,
    },
  },
  {
    breakpoint: 2325,
    settings: {
      slidesToShow: 11,
      slidesToScroll: 11,
    },
  },
  {
    breakpoint: 2114,
    settings: {
      slidesToShow: 10,
      slidesToScroll: 10,
    },
  },
  {
    breakpoint: 1903,
    settings: {
      slidesToShow: 9,
      slidesToScroll: 9,
    },
  },
  {
    breakpoint: 1691,
    settings: {
      slidesToShow: 8,
      slidesToScroll: 8,
    },
  },
  {
    breakpoint: 1480,
    settings: {
      slidesToShow: 7,
      slidesToScroll: 7,
    },
  },
  {
    breakpoint: 1268,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
  },
  {
    breakpoint: 1056,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
    },
  },
  {
    breakpoint: 845,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 4,
    },
  },
  {
    breakpoint: 634,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 423,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 211,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

const getMaxImageRatio = (nodes: TitleCarouselNode[]) =>
  (nodes || []).reduce((ratio, node) => Math.max(ratio, node.imageRatio || 0), 0) || 1.5;

/*
 * Each slide in our React Slick slider consists of a title (image and text) plus right padding.
 * The requirement for title carousels is that the first visible title
 * and the last visible title (if enough titles exist) are flush with the edges of the container.
 * Without modification and in the absence of any relevant setting in the React Slick API,
 * the padding of the rightmost slide will be visible, along with potentially several pixels
 * of the first hidden title due to having remaining pixels that cannot be evenly spread to the visible slides.
 * To remedy this, we need to perform the following steps:
 * - Determine the number of slides showing.
 * - Assuming that each slide must be the same width,
 *   extend the slider width so that for the same number of slides,
 *   the width of the slider up to the right edge of the last visible title
 *   is AT LEAST the width of the container.
 * - Reduce the right margins for all but the last visible slide as evenly as possible
 *   so that the width of the slider up to right edge of the last visible title
 *   is brought down to EXACTLY the width of the container.
 */
const adjustSlideWidth = (carouselEl: HTMLElement) => {
  const carouselWidth = carouselEl.offsetWidth;
  const sliderEl = carouselEl.querySelector('.slick-slider') as HTMLElement;
  const slides: HTMLElement[] = Array.from(sliderEl.querySelectorAll('.slick-slide'));
  const slideWidth = slides[0].offsetWidth;
  const slidesCount = Math.ceil(carouselWidth / slideWidth);
  const gapCount = slidesCount - 1;
  const availableWidthForItems = carouselWidth - gapCount * carouselItemGapPx;
  const desiredItemWidth = Math.ceil(availableWidthForItems / slidesCount);
  const requiredSliderWidth = (desiredItemWidth + carouselItemGapPx) * slidesCount;
  const extraSliderWidth = requiredSliderWidth - carouselWidth;
  const extraGapWidth = desiredItemWidth * slidesCount + carouselItemGapPx * gapCount - carouselWidth;

  sliderEl.style.marginRight = `${-1 * extraSliderWidth}px`;
  slides.forEach((slide) => {
    slide.style.marginRight = '0px';
  });
  if (extraGapWidth > 0) {
    const baseReductionPx = Math.floor(extraGapWidth / gapCount);
    const remainingReductionPx = extraGapWidth % gapCount;
    const activeSlides = slides.filter((slide) => slide.className.includes('slick-active'));
    activeSlides.slice(0, -1).forEach((slide, index) => {
      const extraReductionPx = index < remainingReductionPx ? 1 : 0;
      slide.style.marginRight = `${-1 * (baseReductionPx + extraReductionPx)}px`;
    });
  }
};

interface MouseCoords {
  x: number;
  y: number;
}

export const TitleCarousel = ({ headingTag = 'h2', lazyLoad, nodes, title }: TitleCarouselProps) => {
  const [mouseDownCoords, setMouseDownCoords] = useState<MouseCoords>({ x: 0, y: 0 });
  const [mouseUpCoords, setMouseUpCoords] = useState<MouseCoords>({ x: 0, y: 0 });
  const { windowWidth } = useWindowResize();
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<Slider>(null);
  const { t } = useTranslations(translations);
  const [inViewRef, inView] = useInView(imageInViewOptions);

  const setRefs = useCallback(
    (node) => {
      carouselRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  useEffect(() => {
    const carouselEl = carouselRef.current;
    const slider = sliderRef.current;
    if (carouselEl && slider) {
      slider.slickGoTo(0);
      adjustSlideWidth(carouselEl);
      adjustButtonPosition(carouselEl);
    }
  }, [windowWidth]);

  useDebug('app:cmp:TitleCarousel', { props: { nodes, title } });

  if (!(nodes || []).length) {
    return null;
  }

  const sliderSettings = {
    ...defaultSliderSettings,
    responsive,
    arrows: true,
    cssEase: 'linear',
    dots: false,
    focusOnSelect: false,
    nextArrow: <CarouselArrow buttonLabel={t('scrollNextAriaLabel', 'Scroll to next')} direction='right' />,
    prevArrow: <CarouselArrow buttonLabel={t('scrollPreviousAriaLabel', 'Scroll to previous')} direction='left' />,
    slidesToScroll: maxSlidesToShow,
    slidesToShow: maxSlidesToShow,
    speed: 500,
    swipeToSlide: false,
    onReInit: () => {
      const carouselEl = carouselRef.current;
      const slider = sliderRef.current;
      if (carouselEl && slider) {
        const slidesToShow = getSlidesToShow(slider, responsive, maxSlidesToShow);
        adjustSlideAttributes(
          carouselEl,
          slidesToShow,
          `[data-carousel="${carouselItemTitleValue}"] a`,
          !lazyLoad || inView,
        );
      }
    },
  };

  const handleSliderClickEvent = (e: React.MouseEvent) => {
    const deltaX = mouseUpCoords.x - mouseDownCoords.x;
    const deltaY = mouseUpCoords.y - mouseDownCoords.y;
    const deltaDistance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
    if (deltaDistance > 50) {
      // Prevent navigation during swipes
      e.preventDefault();
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setMouseDownCoords({
      x: e.nativeEvent.clientX,
      y: e.nativeEvent.clientY,
    });
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    setMouseUpCoords({
      x: e.nativeEvent.clientX,
      y: e.nativeEvent.clientY,
    });
  };

  const titleHeadingTag = headingTag;
  const itemHeadingTag = `h${+headingTag.split('')[1] + 1}` as HeadingTag;

  const imageRatio = getMaxImageRatio(nodes);
  const items = nodes.map((node: TitleCarouselNode) => (
    <CarouselItem
      handleMouseDown={handleMouseDown}
      handleMouseUp={handleMouseUp}
      handleSliderClickEvent={handleSliderClickEvent}
      headingTag={itemHeadingTag}
      imageRatio={imageRatio}
      key={node.url}
      lazyLoad={lazyLoad}
      node={node}
    />
  ));

  return (
    <StyledTitleCarousel>
      {title && <RowHeader headingTag={titleHeadingTag} title={title} />}
      <StyledPaddingContainer>
        <StyledTitleSliderContainer ref={setRefs}>
          <Slider {...sliderSettings} ref={sliderRef}>
            {items}
          </Slider>
        </StyledTitleSliderContainer>
      </StyledPaddingContainer>
    </StyledTitleCarousel>
  );
};
