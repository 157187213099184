import { Link } from '@reach/router';
import * as React from 'react';
import { TranslationFn, useTranslations } from '../../../common/translation';
import translations from '../translations';
import { CarouselItemTitleProps } from './CarouselItemTitle.props';
import { StyledCarouselItemHeader, StyledCarouselItemTitle } from './CarouselItemTitle.styles';

export const carouselItemTitleValue = 'item-title';

const getTypeLabel = (type: ContentType | undefined, isMobileGameOrApp: boolean | undefined, t: TranslationFn) => {
  switch (type) {
    case 'Games & Apps':
      return isMobileGameOrApp ? t('Mobile Apps', 'Mobile Apps') : t('Video Games', 'Video Games');
    case 'Movies':
    case 'TV Seasons':
    case 'TV Series':
      return t(type, type);
    default:
      return '';
  }
};

export const CarouselItemTitle = ({
  headingTag = 'h3',
  node: { isMobileGameOrApp, marketingMessage, title, type, url },
}: CarouselItemTitleProps) => {
  const { t } = useTranslations(translations);
  const typeLabel = getTypeLabel(type, isMobileGameOrApp, t);

  return (
    <StyledCarouselItemHeader data-carousel={carouselItemTitleValue}>
      {typeLabel && <span>{typeLabel}</span>}
      <StyledCarouselItemTitle as={headingTag}>
        <Link to={url}>{title}</Link>
      </StyledCarouselItemTitle>
      {marketingMessage && <p>{marketingMessage}</p>}
    </StyledCarouselItemHeader>
  );
};
