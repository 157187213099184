import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { white } from '../../../common/colors';
import { StyledPaddingContainer } from '../../../common/padding.styles';

const shadowColor = 'rgba(0,0,0,0.5)';
const shadowOffset = '1px';
const shadowBlur = '3px';

export const StyledFeaturedBlog = styled.div`
  position: relative;
  height: 100%;
  color: ${white};
  overflow: hidden;

  & > a {
    display: block;
    height: 100%;
    line-height: 0;
    text-decoration: none;
    cursor: pointer;

    & > img {
      width: 100%;
    }
  }
`;

export const StyledFBContentWrapper = styled(StyledPaddingContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: ${rem(20)};
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`;

export const StyledFBPublishDate = styled.p`
  margin: 0;
  font-size: ${rem(12)};
  line-height: ${rem(26)};
  color: ${white};
  text-shadow: -${shadowOffset} -${shadowOffset} ${shadowBlur} ${shadowColor},
    ${shadowOffset} -${shadowOffset} ${shadowBlur} ${shadowColor},
    -${shadowOffset} ${shadowOffset} ${shadowBlur} ${shadowColor},
    ${shadowOffset} ${shadowOffset} ${shadowBlur} ${shadowColor};
`;

export const StyledFBContentTitle = styled.h2`
  margin: 0;
  font-size: ${rem(16)};
  line-height: ${rem(16)};
  color: ${white};
  text-shadow: -${shadowOffset} -${shadowOffset} ${shadowBlur} ${shadowColor},
    ${shadowOffset} -${shadowOffset} ${shadowBlur} ${shadowColor},
    -${shadowOffset} ${shadowOffset} ${shadowBlur} ${shadowColor},
    ${shadowOffset} ${shadowOffset} ${shadowBlur} ${shadowColor};

  ${mediaQueryWidth({ min: 'desktop' })`
    font-size: ${rem(20)};
    line-height: ${rem(20)};
  `};

  ${mediaQueryWidth({ max: 'tablet' })`
    font-size: ${rem(20)};
    line-height:${rem(20)};
  `};
`;

export const StyledFBContentSubtitle = styled.p`
  margin-bottom: 0;
  font-size: ${rem(12)};
  font-weight: 800;
  line-height: ${rem(16)};
  color: ${white};
  text-shadow: -${shadowOffset} -${shadowOffset} ${shadowBlur} ${shadowColor},
    ${shadowOffset} -${shadowOffset} ${shadowBlur} ${shadowColor},
    -${shadowOffset} ${shadowOffset} ${shadowBlur} ${shadowColor},
    ${shadowOffset} ${shadowOffset} ${shadowBlur} ${shadowColor};
`;

export const StyledFBContent = styled.div`
  padding-left: ${rem(22)};
  border-left: 0.1rem solid rgba(255, 255, 255, 0.4);
`;

export const StyledFBImgFallback = styled.div`
  width: 100%;
  height: 0;
  padding-top: 80%;
`;
