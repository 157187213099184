import styled from 'styled-components';
import { white } from '../../common/colors';
import { StyledSliderContainer } from '../../common/slider.styles';

export const StyledTitleCarousel = styled.section`
  background-color: ${white};
`;

export const StyledTitleSliderContainer = styled(StyledSliderContainer)`
  position: relative;
  overflow: hidden;

  .slick-slider {
    position: static;
  }
`;
