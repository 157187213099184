import { Link } from '@reach/router';
import * as React from 'react';
import { buttonGuideValue } from '../../../common/helpers/carousel';
import { AspectRatioImage } from '../../aspect-ratio-image/AspectRatioImage';
import { CarouselItemTitle } from '../carousel-item-title/CarouselItemTitle';
import { CarouselItemProps } from './CarouselItem.props';
import { StyledCarouselItem, StyledCarouselItemImage } from './CarouselItem.styles';

export const CarouselItem = ({
  handleMouseDown,
  handleMouseUp,
  handleSliderClickEvent,
  headingTag,
  imageRatio,
  lazyLoad,
  node,
}: CarouselItemProps) => {
  const { image, title, url } = node;
  const imageWithDefaults = {
    src: image.src || '/assets/images/keyart/default-wb.jpg',
    alt: image.alt || title,
  };

  return (
    <StyledCarouselItem>
      <StyledCarouselItemImage>
        <Link
          aria-hidden='true'
          className='slide-link'
          data-carousel={buttonGuideValue}
          key={url}
          onClick={handleSliderClickEvent}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          tabIndex={-1}
          to={url}
        >
          <AspectRatioImage image={imageWithDefaults} lazyLoad={lazyLoad} ratio={imageRatio || 1.5} />
        </Link>
      </StyledCarouselItemImage>
      {title && <CarouselItemTitle headingTag={headingTag} node={node} />}
    </StyledCarouselItem>
  );
};
