import { Link, navigate } from '@reach/router';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import { formattedDate } from '../../../common/helpers/date';
import { truncate } from '../../../common/helpers/strings';
import { useLocale } from '../../../common/hooks/useLocale';
import { useWindowResize } from '../../../common/hooks/useWindowResize';
import { imageInViewOptions } from '../../../common/loading';
import { Img } from '../../img/Img';
import { FeaturedBlogProps } from './FeaturedBlog.props';
import {
  StyledFBContent,
  StyledFBContentSubtitle,
  StyledFBContentTitle,
  StyledFBContentWrapper,
  StyledFBImgFallback,
  StyledFBPublishDate,
  StyledFeaturedBlog,
} from './FeaturedBlog.styles';

export const FeaturedBlog = ({ image, lazyLoad, publishDate, subTitle, title, url }: FeaturedBlogProps) => {
  const [inViewRef, inView] = useInView(imageInViewOptions);
  const { isSmallDesktop } = useWindowResize();
  const locale = useLocale();

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      navigate(url);
    }
  };

  const truncatedHeading = (heading: string): string => (isSmallDesktop ? truncate(heading, 70) : heading);
  const localeDate = formattedDate(publishDate, locale);
  const truncatedTitle = truncatedHeading(title);
  const truncatedSubtitle = truncatedHeading(subTitle);

  const showImage = inView || !lazyLoad;
  const backgroundImage =
    image && showImage ? (
      <Img alt={image.alt} src={image.src || '/assets/images/promo/default-wb.jpg'} />
    ) : (
      <StyledFBImgFallback />
    );

  return (
    <StyledFeaturedBlog ref={inViewRef}>
      <Link to={url} target='_blank' tabIndex={-1}>
        {backgroundImage}
        <StyledFBContentWrapper>
          <StyledFBContent>
            {localeDate && <StyledFBPublishDate>{localeDate}</StyledFBPublishDate>}
            {truncatedTitle && (
              <StyledFBContentTitle tabIndex={0} onKeyDown={handleEnter}>
                {truncatedTitle}
              </StyledFBContentTitle>
            )}
            {truncatedSubtitle && <StyledFBContentSubtitle>{truncatedSubtitle}</StyledFBContentSubtitle>}
          </StyledFBContent>
        </StyledFBContentWrapper>
      </Link>
    </StyledFeaturedBlog>
  );
};
